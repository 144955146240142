import firebase from 'firebase/compat/app';
import { Asset } from './asset';

export enum ValuationStatus {
  Pending = 'pending',
  Applied = 'applied',
}

/**
 * Valuation structure. This is a subcollection inside Asset.
 */
export interface Valuation {
  id?: string;
  asset: firebase.firestore.DocumentReference | Asset;
  sharePrice: number;
  totalValueShares: number;
  description: string;
  deleted: boolean;
  status: ValuationStatus;
  applyDateTime: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
}
