/**
 * Client configs.
 */

module.exports = {
  mobilePhoneApp: false,
  currency: '€',
  logo: 'arkensteen/arkensteen.svg',
  logoAlt: 'arkensteen/arkensteen-alt.svg',
  logoShare: 'bloqhouse.png',
  logoPremium: 'arkensteen-premium.svg',
  location: 'EUROPE-WEST1',
  internalClientName: 'arkensteen',
  iconName1: 'favicon.ico',
  iconName2: 'favicon.svg',
  iconName3: 'favicon-512.png',
  scssVariables: './src/scss/variables/brands/_arkensteen.scss',
  name: 'Arkensteen',
  phone: '+31 (0)6-21145109',
  email: 'info@arkensteen.nl',
  website: 'https://arkensteen.nl',
  glossary: true,
  langFileName: 'arkensteen/language-config.ts',
  paymentServiceProvider: 'Custom',
  oppSource: '',
  bloqhouseBrandingEnabled: true,
  pescheckEnabled: true,
  modules: {
    landing: false,
    identification: {
      default: true, // setting this to false requires manual changes,
      itsme: false,
    },
  },
  landingRedirect: '/all-campaigns',
  loginRedirect: '/account',
  intercom: false,
  requireContractAgreement: true,
  requireQuestionnaire: false,
  functionality: {
    enableTradingBoard: false,
    idinGlobalSwitch: false,
    containerGlobalSwitch: false,
    progressEndpoint: false,
    loanEmail: false, // THIS IS MEERDERVOORT HARDCODED. KEEP DISABLED FOR ALL CLIENTS THAT ARE NOT MEERDERVOORT
    questionnaire: {
      folderName: 'default',
    },
    checkout: {
      initCheckoutStore: 'default',
      stylingFolderName: 'default',
      remoteCheckoutEnabled: false,
      steps: ['Questionnaire', 'Identification', 'Investment', 'Terms', 'Payment'],
      resetCheckoutExceptWhenAt: ['Terms', 'Questionnaire'],
      enableCheckoutQuestionnaire: false,
      checkoutInvestment: {
        folderName: 'default',
        firstParagraphEnabled: false,
        showMinimumAmount: true,
      },
      checkoutLegal: {
        enableGeneralAgreement: false,
        enableLegalDoc: false,
        footerButtonText: 'checkout.investment.nextStep',
        footerButtonNoteText: 'checkout.legal.continue',
      },
      checkoutFooter: {
        rowJustifyContentEnabled: false,
        checkoutUpdateType: 'default',
      },
      checkoutQuestionnaire: {
        folderName: 'default',
      },
      checkoutRiskQuestionnaire: {
        folderName: 'default',
      },
      checkoutRecap: {
        finalisingPaymentTextEnabled: true,
      },
      checkoutStatus: {
        statusEnabled: true,
        titleEnabled: true,
        navigateToProjectButtonEnabled: true,
        showButtons: true,
      },
    },
    paymentInitTransaction: {
      loanTypeFundMax: false,
      containsLegalTerms: false,
      paymentInit: {
        fundTypeEnabled: false,
        signatureChoiceEnabled: false,
        legalDocsEnabled: false,
        questionaireQuestionAndAnswersEnabled: false,
        knowledgeQuestionAndAnswersEnabled: false,
      },
    },
    generateDividendsEarnings: 'generateDividendsPDF',
    headerForPDF: false,
    customSignUpGreeting: '',
    index: {
      preloadHref:
        '"https://api.fonts.coollabs.io/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,500;1,600&display=swap"',
      stylesheetHref:
        '"https://api.fonts.coollabs.io/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,500;1,600&display=swap"',
    },
    dividendsFormat: true,
    dividendsReturnsOptions: {
      checkIfAssetHasFixedDividends: true,
    },
    bannerLegal: {
      type: 'image',
      requireLegalBanner: true,
      legalBannerNoRequiresLicense: true,
      legalBannerPosition: 'top',
      phoneNumber: false,
    },
    navbar: {
      boxedEnabled: true,
      languageChoice: {
        enableLanguageChoice: true,
        languages: ['nl', 'en'],
        defaultLanguage: 'nl',
      },
      externalLink: 'https://arkensteen.nl',
      backgroundPrimary: false,
      containerEnabled: false,
      shadowEnabled: true,
      authPageRedirect: 'login',
      customNonLocalNonAuthLogin: false,
      logoSize: 'small',
      showDropDownMenu: true,
      navbarLinks: {
        allCampaigns: {
          visible: true,
        },
        knowledge: {
          visible: false,
          url: '',
        },
        home: {
          visible: true,
        },
      },
      navIcon: {
        homeIcon: true,
        projectsIcon: true,
      },
    },
    accountSettings: {
      salutation: true,
      initials: true,
      middleName: true,
      country: true,
    },
    accountDashboard: {
      holdingsImagePath: '@/assets/logos/arkensteen.svg',
      pendingPaymentsInfoboxEnabled: false,
    },
    accountBalance: {
      customImage: true,
      customWrapperClass: 'account__balance',
      animationsEnabled: true,
    },
    accountInvestments: {
      transactionAmountWrapperClassName: 'transactions-item-total',
      boughtSharesExcludeLoan: false,
      checkForPremium: true,
    },
    accountSingleFund: {
      availableProjectsTotal: 'available',
      textCheckFundType: false,
      displayInterest: true,
      overlayClassEnabled: false,
      firstWithDownloadVersion: 'assets',
      totalsSectionEnabled: true,
      totalsSectionTwoEnabled: false,
      totalsSource: 'investment',
      dividendsSource: 'dividends',
      netValueCheckIfNoInvestments: false,
      includeIcon: false,
      completedBoxTextClassEnabled: true,
      premiumClassEnabled: true,
      paymentDateEarningsHistory: true,
    },
    property: {
      secondaryDocUnitedStates: false,
      secondaryDocThreshold: false,
      showContactAddress: false,
      availableNumbersEnabled: true,
      showInterestEnabled: true,
      transformedDateTimeEnabled: false,
      openCarousel: true,
    },
    propertiesGridFund: {
      disableDocsIfNotInvested: true,
      contentsCardEnabled: true,
      subtitle: 'tooltipAndTitle',
    },
    properties: {
      toolbarSearchEnabled: false,
      dateSortingProperty: 'createdDateTime',
      titleEnabled: false,
    },
    identificationRequest: {
      imageFolderWorld: 'default/arkensteen/world.svg',
      imageFolderBusiness: 'default/arkensteen/company.svg',
      imageFolderWorldIcon: 'default/world-ico.svg',
      imageFolderBusinessIcon: 'default/company-ico.svg',
      steps: ['identification'],
      dateOfBirthFormat: 'DD/MM/YYYY',
      uploadPassport: true,
      businessRiskClassProp: false,
      businessExperienceProp: false,
      worldRiskClassProp: false,
      worldExperienceProp: false,
      businessInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: 'tel',
          label: 'common.telephone',
          inputType: 'formInput',
        },
        dateOfBirth: {
          inputName: 'dateOfBirth',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.dateOfBirth',
        },
        bankAccount: {
          inputName: 'bankAccount',
          rules: 'required|alpha_num|max:40|min:18',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.iban',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.houseNumber',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        companyName: {
          inputName: 'companyName',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.companyName',
          inputType: 'formInput',
        },
        kvkNumber: {
          inputName: 'kvkNumber',
          rules: 'required|integer',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'identification.business.kvkNumber',
          inputType: 'formInput',
        },
        kvkImage: {
          inputName: 'kvkImage',
          rules: '',
          class: 'col--12 upload-passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.business.uploadYourKvKImage',
        },
        passport: {
          inputName: 'passport',
          rules: '',
          class: 'col--12 upload-passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
        sourceOfFunds: {
          inputName: 'sourceOfFunds',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'sourceOfFundsOptions',
          label: 'identification.sourceOfFunds',
          selectCustomInputCondition: {
            inputName: 'sourceOfFundsCustomAnswer',
            expectedValue: 'other',
          },
        },
        investmentsIn: {
          inputName: 'investmentsIn',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.pleaseSpecify',
          inputType: 'radio',
          displayFlex: false,
          radioOptions: [
            {
              label: 'identification.financialInstruments',
              optionValue: 'financialInstruments',
            },
            {
              label: 'identification.privateEquity',
              optionValue: 'privateEquity',
            },
            {
              label: 'identification.realEstate',
              optionValue: 'realEstate',
            },
            {
              label: 'identification.criptoCurrencies',
              optionValue: 'cryptoCurrencies',
            },
            {
              label: 'identification.anotherInvestments',
              optionValue: 'anotherInvestments',
            },
          ],
          enabledConditions: [
            {
              inputName: 'sourceOfFunds',
              expectedValue: 'proceedsFromInvestments',
            },
          ],
        },
        sourceOfFundsDescription: {
          inputName: 'sourceOfFundsDescription',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.pleaseSpecify',
          inputType: 'formInput',
          displayFlex: false,
          enabledConditions: [
            {
              inputName: 'investmentsIn',
              expectedValue: 'anotherInvestments',
            },
            {
              inputName: 'sourceOfFunds',
              expectedValue: 'employmentIncome',
            },
            {
              inputName: 'sourceOfFunds',
              expectedValue: 'proceedsFromBusiness',
            },
            {
              inputName: 'sourceOfFunds',
              expectedValue: 'heritage',
            },
          ],
        },
        sourceOfFundsDoc: {
          inputName: 'sourceOfFundsDoc',
          rules: 'required',
          class: 'col--12 upload-passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.uploadSourceOfFundsDoc',
        },
      },
      worldInputs: {
        gender: {
          inputName: 'gender',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'genderOptions',
          label: 'common.gender',
        },
        firstName: {
          inputName: 'firstName',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--12',
          autocomplete: true,
          type: '',
          label: 'common.firstName',
          inputType: 'formInput',
        },
        surname: {
          inputName: 'surname',
          rules: 'required|alpha_spaces|max:40',
          class: 'col--6',
          autocomplete: true,
          type: '',
          label: 'common.surname',
          inputType: 'formInput',
        },
        telephone: {
          inputName: 'telephone',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: 'tel',
          label: 'common.telephone',
          inputType: 'formInput',
        },
        dateOfBirth: {
          inputName: 'dateOfBirth',
          rules: 'required',
          class: 'col--6',
          autocomplete: true,
          type: '',
          inputType: 'formDatePicker',
          label: 'common.dateOfBirth',
        },
        bankAccount: {
          inputName: 'bankAccount',
          rules: 'required|alpha_num|max:40|min:18',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.iban',
          inputType: 'formInput',
        },
        nationality: {
          inputName: 'nationality',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.nationality',
        },
        address: {
          inputName: 'address',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.address',
          inputType: 'formInput',
        },
        houseNumber: {
          inputName: 'houseNumber',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.houseNumber',
          inputType: 'formInput',
        },
        city: {
          inputName: 'city',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.city',
          inputType: 'formInput',
        },
        postalCode: {
          inputName: 'postalCode',
          rules: 'required',
          class: 'col--4',
          autocomplete: false,
          type: '',
          label: 'common.postalCode',
          inputType: 'formInput',
        },
        country: {
          inputName: 'country',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'countries',
          label: 'common.country',
        },
        passport: {
          inputName: 'passport',
          rules: '',
          class: 'col--12 upload-passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.world.uploadYourPassport',
        },
        sourceOfFunds: {
          inputName: 'sourceOfFunds',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          inputType: 'formSelect',
          options: 'sourceOfFundsOptions',
          label: 'identification.sourceOfFunds',
          selectCustomInputCondition: {
            inputName: 'sourceOfFundsCustomAnswer',
            expectedValue: 'other',
          },
        },
        investmentsIn: {
          inputName: 'investmentsIn',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.pleaseSpecify',
          inputType: 'radio',
          displayFlex: false,
          radioOptions: [
            {
              label: 'identification.financialInstruments',
              optionValue: 'financialInstruments',
            },
            {
              label: 'identification.privateEquity',
              optionValue: 'privateEquity',
            },
            {
              label: 'identification.realEstate',
              optionValue: 'realEstate',
            },
            {
              label: 'identification.criptoCurrencies',
              optionValue: 'cryptoCurrencies',
            },
            {
              label: 'identification.anotherInvestments',
              optionValue: 'anotherInvestments',
            },
          ],
          enabledConditions: [
            {
              inputName: 'sourceOfFunds',
              expectedValue: 'proceedsFromInvestments',
            },
          ],
        },
        sourceOfFundsDescription: {
          inputName: 'sourceOfFundsDescription',
          rules: 'required',
          class: 'col--12',
          autocomplete: false,
          type: '',
          label: 'common.pleaseSpecify',
          inputType: 'formInput',
          displayFlex: false,
          enabledConditions: [
            {
              inputName: 'investmentsIn',
              expectedValue: 'anotherInvestments',
            },
            {
              inputName: 'sourceOfFunds',
              expectedValue: 'employmentIncome',
            },
            {
              inputName: 'sourceOfFunds',
              expectedValue: 'proceedsFromBusiness',
            },
            {
              inputName: 'sourceOfFunds',
              expectedValue: 'heritage',
            },
          ],
        },
        sourceOfFundsDoc: {
          inputName: 'sourceOfFundsDoc',
          rules: 'required',
          class: 'col--12 upload-passport',
          autocomplete: false,
          type: '',
          inputType: 'formFileInput',
          label: 'identification.uploadSourceOfFundsDoc',
        },
      },
    },
    identificationStep: {
      questionnaireEnabled: true,
    },
    identificationForm: {
      redirectToAccount: false,
      buttonText: 'dashboard.funds.myPortfolio',
    },
    settings: {
      bankAccountDetailsChangeEnabled: true,
      itemPaddingAdded: false,
      details: 'full',
      detailsChange: true,
      identification: true,
      settingsAutoReditect: 'identification',
    },
    register: {
      privacyStatementUrl: '',
      enableLanguageSelect: false,
      newsletter: false,
      privacyStatement: false,
    },
    login: {
      enabled2FA: false,
    },
    authReset: {
      includePleaseCheckInboxInToast: true,
    },
    dropdownMenu: {
      dropdownMenuType: 'standard',
      settingsNavigation: 'settings',
      dropdownMenuItems: {
        portfolio: {
          visible: true,
        },
        settings: {
          icon: 'cog',
          visible: true,
        },
      },
    },
    investor: {
      passportPropertyName: 'passport',
    },
    footer: {
      type: 'standard',
      brandLogo: 'bloqhouse',
    },
  },
  emails: {
    from: 'Pieter van Herk',
    userCreatedFromBloqadmin: {
      templateId: 'd-9413761fd50b4c5f9696515b41b05375', // This is still the  fundmgtdemo templates
    },
    sendSharesEmail: {
      format: 'default',
    },
    sharesBought: {
      format: 'full',
      templateId: 'd-25deb9d03c0e46b8a5e68d1077c9b4ba',
      enabled: false,
      signatureChoiceOnline: '',
      fromAdminTemplateId: '',
      sendlegalDocs: false,
    },
    sharesBoughtAdminNotification: {
      to: 'test@bloqhouse.com',
      templateId: '',
      enabled: false,
      signatureChoiceOnline: '',
    },
    verifyEmail: {
      templateId: 'd-a9d1de39da15494cbcb35a5fa989fb75',
      format: 'subjectDirectorRegardsWelcomeActivate',
    },
    passwordReset: {
      templateId: 'd-8637f22fd70c495f9b4b3d2034c43017',
      format: 'directorRegardsForgotReset',
    },
    identificationRequest: {
      approved: {
        templateId: 'd-c344e8fb719c492289b570df610cfb4f',
      },
      rejected: {
        templateId: 'd-fe7e3c6acbc94eb89a920256be3b14ae',
      },
      format: 'salutationSurnameUrl',
    },
    createIdentificationRequest: {
      fromClientName: false,
      toClientName: false,
      toClientEmail: [],
    },
    translations: {
      sharesBoughtInstrument: {
        subject: 'Obligaties',
        content: 'obligatiehouder',
      },
    },
    contactSupportByEmail: {
      from: '',
      to: '',
      cc: false,
    },
  },
};
