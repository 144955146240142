import firebase from 'firebase/compat/app';
import { Investment, PaymentRelation } from './investment';
import { Investor } from './user';
import { Asset } from './asset';
import { TransactionPaymentMethods } from './opp';

/**
 * Defining all the statuses a Bid can have.
 */
export enum BidStatus {
  Open = 'open',
  Reserved = 'reserved',
  Completed = 'completed',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Failed = 'failed',
}

/**
 * Different bid gateways/providers we use.
 */
export const enum BidProvider {
  Opp = 'opp',
  Custom = 'custom',
}

export enum Step {
  LegalAgreement = 'legalAgreement',
  PaymentOptions = 'paymentOptions',
  Processing = 'processing',
  Success = 'success',
}

/**
 * Sell or buy operation
 */
export const enum BidType {
  Sell = 'sell',
  Buy = 'buy',
}

export interface BidIds {
  investmentId: string;
  bidId: string;
}

export interface DefaultBid {
  id?: string;
  ref?: firebase.firestore.DocumentReference;
  investor: firebase.firestore.DocumentReference | Investor | string;
  investment: firebase.firestore.DocumentReference | Investment | string;
  asset: firebase.firestore.DocumentReference | Asset | string;
  status: BidStatus;
  type: BidType;
  provider: BidProvider;
  partner?: firebase.firestore.DocumentReference; // Related bid (sell - buy relationship). Setted once the bid is reserved
  payment?: firebase.firestore.DocumentReference; // Payment created when the exchange is complete
  deleted: boolean;
  mode: 'active' | 'passive';
  sharesAmount: number;
  euroAmount: number;
  negotiations: string[] | firebase.firestore.FieldValue;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
  expireDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
}

export interface BuyingBid extends DefaultBid {
  type: BidType.Buy;
  transactionUid?: string;
}

export interface SellingBid extends DefaultBid {
  type: BidType.Sell;
  usedPayments: PaymentRelation[] | firebase.firestore.FieldValue;
}

export type Bid = BuyingBid | SellingBid;

export interface CreateBidInput {
  investorId: string;
  assetId: string;
  type: BidType;
  sharesAmount: number;
  euroAmount: number;
  provider: BidProvider;
  mode: 'active' | 'passive';
  expireDateTime?: number;
}

export interface RelateBidsInput {
  buyingBid: BidIds;
  sellingBid: BidIds;
}

export interface ReserveBidInput {
  bid: BidIds;
  investorId: string;
  autoComplete: boolean;
  flow: 'request' | 'direct';
  paymentMethod?: TransactionPaymentMethods;
  lang?: string;
}

export interface TransferInput {
  originInvestorId: string;
  destinyInvestorId: string;
  assetId: string;
  sharesAmount: number;
  euroAmount: number;
  transferDate: number;
}

export interface NegociateBidInput {
  bid: BidIds;
  investorId: string;
  lang?: string;
}
