import firebase from 'firebase/compat/app';
import { Investor } from './user';
import { Asset } from './asset';

/**
 * The upper db object for an Investment.
 * An investments always contains a subcollection of Payments.
 */
export interface Investment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  asset: firebase.firestore.DocumentReference | Asset;
  paidEuroTotal?: number;
  boughtSharesTotal?: number;
  totalEuroRepayments?: number;
  totalSharesRepayments?: number;
  totalEuroEarnings?: number;
  totalEuroCosts?: number;
  openPayments?: number;
  paidPayments?: number;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  signChoice?: string;
  identifier?: string;
}

export const enum ExtendPaymentMethod {
  wallet = 'wallet',
}

export interface PaymentRelation {
  ref: firebase.firestore.DocumentReference;
  sharesAmount: number;
}

/**
 * Defining all the statuses a Payment can have.
 */
export enum PaymentStatus {
  Open = 'open',
  Canceled = 'canceled',
  Pending = 'pending',
  Authorized = 'authorized',
  Expired = 'expired',
  Failed = 'failed',
  Paid = 'paid',
  Requested = 'requested',
  Refund = 'refund',
  PartialRefund = 'partial_refund',
  ChargeBack = 'chargeback',
  Unknown = 'unknown',
}

/**
 * Different payment gateways/providers we use.
 */
export enum PaymentProvider {
  Mollie = 'Mollie',
  PayNL = 'PayNL',
  OPP = 'OPP',
  /**
   * Manual, from Bloqadmin.
   */
  Custom = 'Custom',
}

/**
 * Sell or buy operation
 */
export const enum PaymentType {
  Sell = 'sell',
  Buy = 'buy',
}

export interface DefaultPayment {
  id?: string;
  investor: firebase.firestore.DocumentReference | Investor;
  investment: firebase.firestore.DocumentReference | Investment;
  asset: firebase.firestore.DocumentReference | Asset;
  dividendsFormat: [string, number];
  provider: PaymentProvider;
  providerData: {
    id: string;
    amount: {
      currency: string;
      value: string | number;
    };
    status: PaymentStatus;
    metadata: {
      uid?: string;
      euroAmount: number;
      sharesAmount: number;
      investmentId?: string;
      assetId?: string;
      paymentId?: string;
      selectedDividendsFormatYear?: [string, number];
    };
    paymentUrl?: string;
    paymentMethod?: string;
    [key: string]: unknown;
  };
  scheduleEnding?: boolean;
  sharesReserved?: number;
  usedEndPayments?: {
    investmentId: string;
    paymentId: string;
    amount: number;
  }[];
  legalDocuments?: [];
  deleted: boolean;
  ended?: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  requestedDateTime?: firebase.firestore.Timestamp;
  transactionDate?: firebase.firestore.Timestamp;
  comment?: string;
  type?: PaymentType;
  from?: firebase.firestore.DocumentReference | DefaultPayment;
  to?: firebase.firestore.DocumentReference | DefaultPayment;
}

export interface BuyingPayment extends DefaultPayment {
  type: PaymentType.Buy;
  from?: firebase.firestore.DocumentReference;
  usedShares: PaymentRelation[];
}

export interface SellingPayment extends DefaultPayment {
  type: PaymentType.Sell;
  to?: firebase.firestore.DocumentReference;
  usedPayments: PaymentRelation[];
}

export type Payment = BuyingPayment | SellingPayment | DefaultPayment;

/**
 * Common result interface for all PSP
 */
export interface PaymentResult {
  id: string;
  uid: string;
  /** In Decimal */
  euroAmount: number;
  /** In Decimal */
  euroAmountWithEmissionCost: number;
  sharesAmount: number;
  paymentId: string;
  investmentId: string;
  assetId: string;
  redirectUrl?: string;
  paymentUrl?: string;
  status?: PaymentStatus;
}
