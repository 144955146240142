import { db } from '@/firebase';
import { Asset } from '@/store/models/asset';
import { Investment, Payment } from '@/store/models/investment';
import { InvestmentRepayment } from '@/store/models/repayment';
import to from 'await-to-js';
import { IdentificationCheckoutStepNames } from '@/store/models/checkout';
import firebase from 'firebase/compat/app';
import { clientConfig } from '@/helpers/clientData';
import { Investor, User, UserTier } from '@/store/models/user';

const whitelabelConfig = clientConfig();
const stepsIdentificationReq = whitelabelConfig.functionality.identificationRequest.steps;

/**
 * getId (par): string
 * Due to permissions errors we need to limit the vuexfire autoresolve feature. That creates an scenario in which object references
 * in a db object can be now an string, firestore reference or the object itself. This method retrieves the id in all the cases
 * @param par relationship field
 * @returns model id
 */
export function getId(
  par: string | firebase.firestore.DocumentReference | Asset | Investment | Payment | InvestmentRepayment | Investor,
): string {
  if (typeof par === 'string') {
    return par.split('/').pop()!;
  }
  return par?.id || '';
}

// This needs to live separately compared to the checkAndUpdateRouteIfNecessary in Checkout.ts unless there is a major refactoring because there is no exact overlap as is
export async function checkAndUpdateRouteIfNecessaryIR(user: User, router: unknown, route: unknown): Promise<void> {
  const [getIDRError, getIDRSuccess] = await to(db.collection('identificationRequests').doc(user?.id).get());

  if (user && user?.tier === UserTier.Account) {
    // identification
    if (
      (getIDRError || !getIDRSuccess?.exists) &&
      (!stepsIdentificationReq.includes('questionnaire') || !user.questionnaireAnswered)
    ) {
      // @ts-expect-error - ToDo: fix this error
      void router.replace({ path: `/${route.params.lang}/identification-checkout/identification/` });
      return;
    }
    // questionnaire
    if (
      getIDRSuccess?.exists &&
      stepsIdentificationReq.includes(IdentificationCheckoutStepNames.Questionnaire) &&
      !user.questionnaireAnswered
    ) {
      // @ts-expect-error - ToDo: fix this error
      void router.push({
        name: `IdentificationCheckout${IdentificationCheckoutStepNames.Questionnaire}`,
        // @ts-expect-error - ToDo: fix this error
        params: { lang: route.params.lang },
      });
      return;
    }
    // simulation
    if (
      getIDRSuccess?.exists &&
      stepsIdentificationReq.includes(IdentificationCheckoutStepNames.RiskQuestionnaire) &&
      user.questionnaireAnswered &&
      !user?.simulationAnswered
    ) {
      // @ts-expect-error - ToDo: fix this error
      void router.push({
        name: `IdentificationCheckout${IdentificationCheckoutStepNames.RiskQuestionnaire}`,
        // @ts-expect-error - ToDo: fix this error
        params: { lang: route.params.lang },
      });
      return;
    }
  }
}
