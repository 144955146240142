import { MutationTree } from 'vuex';
import { vuexfireMutations } from 'vuexfire';
import { firebase } from '@/firebase';
import { generateInitialRootState, State } from '@/store/models';
import * as MUTATIONS from './constants';
import { activeBindings } from './actions';

export default {
  /**
   * Function that restores the state into the initial object,
   * excluding the exception keys and bound properties
   */
  [MUTATIONS.RESET_STATE](state: State, exceptions: string[]): void {
    const initialStateObject = generateInitialRootState();

    const resetKeys = Object.keys(initialStateObject).filter((key: string): boolean => {
      const isNotException = !exceptions.some((excpt: string): boolean => excpt === key);
      const isNotBinding = !activeBindings.some((binding: string): boolean => binding === key);

      return isNotBinding && isNotException;
    });

    resetKeys.forEach((resetKey: string): void => {
      state[resetKey] = initialStateObject[resetKey];
    });
  },
  [MUTATIONS.LOGIN_ERROR](state: State, { name, error }: { name: string; error: Error }): void {
    const newError = JSON.parse(JSON.stringify(error));

    state.auth = {
      name,
      status: 'error',
      action: MUTATIONS.LOGIN_ERROR,
      error: newError,
    };
  },
  [MUTATIONS.LOGIN_PROCESSING](state: State, { name }: { name: string }): void {
    state.auth = {
      name,
      status: 'processing',
      action: MUTATIONS.LOGIN_PROCESSING,
    };
  },
  [MUTATIONS.LOGIN_SUCCESS](state: State, { name, ...payload }: { name: string; [key: string]: unknown }): void {
    const newPayload = JSON.parse(JSON.stringify(payload));

    state.auth = {
      name,
      status: 'success',
      action: MUTATIONS.LOGIN_SUCCESS,
      ...(Object.keys(newPayload).length && {
        payload: newPayload,
      }),
    };
  },
  [MUTATIONS.LOGIN_WARNING](state: State, userCredential: firebase.auth.UserCredential): void {
    state.auth = {
      ...state.auth,
      emailVerified: userCredential.user!.emailVerified || false,
      uid: userCredential.user!.uid || '',
      status: 'warning',
      action: MUTATIONS.LOGIN_WARNING,
    };
  },
  [MUTATIONS.VERIFY_SMS_ERROR](state: State, verifySmsError: firebase.FirebaseError | null | string): void {
    state.verifySMS = {
      status: 'error',
      action: MUTATIONS.VERIFY_SMS_ERROR,
      error: verifySmsError,
    };
  },
  [MUTATIONS.VERIFY_SMS_PROCESSING](state: State): void {
    state.verifySMS = {
      status: 'processing',
      action: MUTATIONS.VERIFY_SMS_PROCESSING,
    };
  },
  [MUTATIONS.VERIFY_SMS_SUCCESS](
    state: State,
    payload: {
      multiFactorAssertion: firebase.auth.PhoneMultiFactorAssertion;
      calledBy: string;
      verificationId: string;
    },
  ): void {
    state.verifySMS = {
      status: 'success',
      action: MUTATIONS.VERIFY_SMS_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.LOGOUT_ERROR](state: State, logOutError: Error): void {
    state.auth = {
      ...state.auth,
      status: 'error',
      error: logOutError.message || 'Something went wrong',
      action: MUTATIONS.LOGOUT_ERROR,
    };
  },
  [MUTATIONS.LOGOUT_SUCCESS](state: State): void {
    state.auth = {
      status: 'success',
      action: MUTATIONS.LOGOUT_SUCCESS,
    };
  },
  [MUTATIONS.LOGOUT_PROCESSING](state: State): void {
    state.auth = {
      ...state.auth,
      status: 'processing',
      action: MUTATIONS.LOGOUT_PROCESSING,
    };
  },
  [MUTATIONS.IDLE_LOGOUT](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.IDLE_LOGOUT,
    };
  },
  [MUTATIONS.SIGNUP_ERROR](state: State, signUpError: Error): void {
    state.auth = {
      status: 'error',
      error: signUpError.message || 'Something went wrong',
      action: MUTATIONS.SIGNUP_ERROR,
    };
  },
  [MUTATIONS.SIGNUP_SUCCESS](state: State, userUid: string): void {
    state.auth = {
      status: 'success',
      action: MUTATIONS.SIGNUP_SUCCESS,
      uid: userUid,
    };
  },
  [MUTATIONS.SIGNUP_PROCESSING](state: State): void {
    state.auth = {
      status: 'processing',
      action: MUTATIONS.SIGNUP_PROCESSING,
    };
  },
  [MUTATIONS.CONTACT_SUPPORT_ERROR](state: State, error: Error): void {
    state.support = {
      status: 'error',
      error: error.message || 'Something went wrong',
      action: MUTATIONS.CONTACT_SUPPORT_ERROR,
    };
  },
  [MUTATIONS.CONTACT_SUPPORT_SUCCESS](
    state: State,
    payload: {
      sendEmailSuccess: firebase.functions.HttpsCallableResult;
    },
  ): void {
    state.support = {
      payload,
      status: 'success',
      action: MUTATIONS.CONTACT_SUPPORT_SUCCESS,
    };
  },
  [MUTATIONS.CONTACT_SUPPORT_PROCESSING](state: State): void {
    state.support = {
      ...state.support,
      status: 'processing',
      action: MUTATIONS.CONTACT_SUPPORT_PROCESSING,
    };
  },
  [MUTATIONS.RESET_PASSWORD_ERROR](state: State, resetPasswordError: Error): void {
    state.operations = {
      name: MUTATIONS.RESET_PASSWORD_ERROR,
      status: 'error',
      error: resetPasswordError.message || 'Something went wrong',
    };
  },
  [MUTATIONS.RESET_PASSWORD_SUCCESS](state: State, resetPasswordSuccess: Error): void {
    state.operations = {
      name: MUTATIONS.RESET_PASSWORD_SUCCESS,
      payload: {
        ...resetPasswordSuccess,
      },
      status: 'success',
    };
  },
  [MUTATIONS.RESET_PASSWORD_PROCESSING](state: State): void {
    state.operations = {
      name: MUTATIONS.RESET_PASSWORD_PROCESSING,
      status: 'processing',
    };
  },
  [MUTATIONS.CHANGE_PASSWORD_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'changePassword',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.CHANGE_PASSWORD_SUCCESS](state: State): void {
    state.operations = {
      name: 'changePassword',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_PASSWORD_PROCESSING](state: State): void {
    state.operations = {
      name: 'changePassword',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_PHONE_NUMBER_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'changePhoneNumber',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.CHANGE_PHONE_NUMBER_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'changePhoneNumber',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_PHONE_NUMBER_PROCESSING](state: State): void {
    state.operations = {
      name: 'changePhoneNumber',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_NAME_SURNAME_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'changeNameSurname',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.CHANGE_NAME_SURNAME_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'changeNameSurname',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_NAME_SURNAME_PROCESSING](state: State): void {
    state.operations = {
      name: 'changeNameSurname',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_ADDRESS_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'changeAddress',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.CHANGE_ADDRESS_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'changeAddress',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_ADDRESS_PROCESSING](state: State): void {
    state.operations = {
      name: 'changeAddress',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.ADD_QUESTIONNAIREANSWERED_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'addQestionnaireAnswered',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.ADD_QUESTIONNAIREANSWERED_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'addQestionnaireAnswered',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.ADD_QUESTIONNAIREANSWERED_PROCESSING](state: State): void {
    state.operations = {
      name: 'addQestionnaireAnswered',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.ADD_SIMULATIONANSWERED_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'addSimulationAnswered',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.ADD_SIMULATIONANSWERED_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'addSimulationAnswered',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.ADD_SIMULATIONANSWERED_PROCESSING](state: State): void {
    state.operations = {
      name: 'addSimulationAnswered',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.ADD_NETINCOME_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'addNetIncome',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.ADD_NETINCOME_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'addNetIncome',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.ADD_NETINCOME_PROCESSING](state: State): void {
    state.operations = {
      name: 'addNetIncome',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.ADD_CAPACITY_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'addCapacity',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.ADD_CAPACITY_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'addCapacity',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.ADD_CAPACITY_PROCESSING](state: State): void {
    state.operations = {
      name: 'addCapacity',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_EMAIL_ERROR](state: State, error?: string): void {
    state.operations = {
      name: 'changeEmail',
      status: 'error',
      error: error || 'Something went wrong',
    };
  },
  [MUTATIONS.CHANGE_EMAIL_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'changeEmail',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.CHANGE_EMAIL_PROCESSING](state: State): void {
    state.operations = {
      name: 'changeEmail',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.SEND_EMAIL_VERIFICATION_ERROR](state: State, error: Error): void {
    state.operations = {
      name: 'sendEmailVerification',
      status: 'error',
      error: error.message || 'Something went wrong',
    };
  },
  [MUTATIONS.SEND_EMAIL_VERIFICATION_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'sendEmailVerification',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.SEND_EMAIL_VERIFICATION_PROCESSING](state: State): void {
    state.operations = {
      name: 'sendEmailVerification',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.UPDATE_USER_ERROR](state: State, error: Error): void {
    state.operations = {
      name: 'updateUser',
      status: 'error',
      error: error.message || 'Something went wrong',
    };
  },
  [MUTATIONS.UPDATE_USER_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      payload,
      name: 'updateUser',
      status: 'success',
      error: '',
    };
  },
  [MUTATIONS.UPDATE_USER_PROCESSING](state: State): void {
    state.operations = {
      name: 'updateUser',
      status: 'processing',
      error: '',
    };
  },
  [MUTATIONS.IDIN_TRANSACTION_ERROR](state: State, idinTransactionError: Error): void {
    state.operations = {
      status: 'error',
      error: idinTransactionError.message || 'Something went wrong',
      name: MUTATIONS.IDIN_TRANSACTION_ERROR,
    };
  },
  [MUTATIONS.IDIN_TRANSACTION_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.IDIN_TRANSACTION_PROCESSING,
    };
  },
  [MUTATIONS.IDIN_TRANSACTION_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.IDIN_TRANSACTION_SUCCESS,
    };
  },
  [MUTATIONS.IDIN_GET_STATUS_ERROR](state: State, idinGetStatusError: Error): void {
    state.operations = {
      status: 'error',
      error: idinGetStatusError.message || 'Something went wrong',
      name: MUTATIONS.IDIN_GET_STATUS_ERROR,
    };
  },
  [MUTATIONS.IDIN_GET_STATUS_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.IDIN_GET_STATUS_PROCESSING,
    };
  },
  [MUTATIONS.IDIN_GET_STATUS_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.IDIN_GET_STATUS_SUCCESS,
    };
  },
  [MUTATIONS.SEND_PRIVATE_IDENTIFICATION_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.SEND_PRIVATE_IDENTIFICATION_PROCESSING,
    };
  },
  [MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.SEND_PRIVATE_IDENTIFICATION_ERROR,
      error,
    };
  },
  [MUTATIONS.SEND_PRIVATE_IDENTIFICATION_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.SEND_PRIVATE_IDENTIFICATION_SUCCESS,
    };
  },
  [MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_PROCESSING,
    };
  },
  [MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_ERROR,
      error,
    };
  },
  [MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.SEND_QUESTIONNAIRE_ANSWERS_SUCCESS,
    };
  },
  [MUTATIONS.SEND_BUSINESS_IDENTIFICATION_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.SEND_BUSINESS_IDENTIFICATION_PROCESSING,
    };
  },
  [MUTATIONS.SEND_BUSINESS_IDENTIFICATION_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.SEND_BUSINESS_IDENTIFICATION_ERROR,
      error,
    };
  },
  [MUTATIONS.SEND_BUSINESS_IDENTIFICATION_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.SEND_BUSINESS_IDENTIFICATION_SUCCESS,
    };
  },
  [MUTATIONS.SEND_ITSME_IDENTIFICATION_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.SEND_ITSME_IDENTIFICATION_PROCESSING,
    };
  },
  [MUTATIONS.SEND_ITSME_IDENTIFICATION_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.SEND_ITSME_IDENTIFICATION_ERROR,
      error,
    };
  },
  [MUTATIONS.SEND_ITSME_IDENTIFICATION_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.SEND_ITSME_IDENTIFICATION_SUCCESS,
    };
  },
  [MUTATIONS.CHANGE_BANK_ACCOUNT_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.CHANGE_BANK_ACCOUNT_PROCESSING,
    };
  },
  [MUTATIONS.CHANGE_BANK_ACCOUNT_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.CHANGE_BANK_ACCOUNT_ERROR,
      error,
    };
  },
  [MUTATIONS.CHANGE_BANK_ACCOUNT_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.CHANGE_BANK_ACCOUNT_SUCCESS,
    };
  },
  [MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_PROCESSING,
    };
  },
  [MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_ERROR,
      error,
    };
  },
  [MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.CHANGE_BANK_ACCOUNT_EMAIL_SUCCESS,
    };
  },
  [MUTATIONS.EMAIL_VERIFICATION_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.EMAIL_VERIFICATION_PROCESSING,
    };
  },
  [MUTATIONS.EMAIL_VERIFICATION_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.EMAIL_VERIFICATION_ERROR,
      error,
    };
  },
  [MUTATIONS.EMAIL_VERIFICATION_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.EMAIL_VERIFICATION_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.CONFIRM_PASSWORD_RESET_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.CONFIRM_PASSWORD_RESET_PROCESSING,
    };
  },
  [MUTATIONS.CONFIRM_PASSWORD_RESET_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.CONFIRM_PASSWORD_RESET_ERROR,
      error,
    };
  },
  [MUTATIONS.CONFIRM_PASSWORD_RESET_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.CONFIRM_PASSWORD_RESET_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.CREATE_DATA_CHANGE_REQUEST_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.CREATE_DATA_CHANGE_REQUEST_PROCESSING,
    };
  },
  [MUTATIONS.CREATE_DATA_CHANGE_REQUEST_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.CREATE_DATA_CHANGE_REQUEST_ERROR,
      error,
    };
  },
  [MUTATIONS.CREATE_DATA_CHANGE_REQUEST_SUCCESS](state: State): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.CREATE_DATA_CHANGE_REQUEST_SUCCESS,
    };
  },
  [MUTATIONS.RECOVER_EMAIL_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.RECOVER_EMAIL_PROCESSING,
    };
  },
  [MUTATIONS.RECOVER_EMAIL_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.RECOVER_EMAIL_ERROR,
      error,
    };
  },
  [MUTATIONS.RECOVER_EMAIL_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.RECOVER_EMAIL_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.REVERT_SECOND_FACTOR_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.REVERT_SECOND_FACTOR_PROCESSING,
    };
  },
  [MUTATIONS.REVERT_SECOND_FACTOR_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.REVERT_SECOND_FACTOR_ERROR,
      error,
    };
  },
  [MUTATIONS.REVERT_SECOND_FACTOR_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.REVERT_SECOND_FACTOR_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.CHECK_ACTION_CODE_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.CHECK_ACTION_CODE_PROCESSING,
    };
  },
  [MUTATIONS.CHECK_ACTION_CODE_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.CHECK_ACTION_CODE_ERROR,
      error,
    };
  },
  [MUTATIONS.CHECK_ACTION_CODE_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.CHECK_ACTION_CODE_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.ASSET_RESERVATION_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.ASSET_RESERVATION_PROCESSING,
    };
  },
  [MUTATIONS.ASSET_RESERVATION_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.ASSET_RESERVATION_ERROR,
      error,
    };
  },
  [MUTATIONS.ASSET_RESERVATION_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.ASSET_RESERVATION_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.SEND_CHECKOUT_SESSION_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.SEND_CHECKOUT_SESSION_PROCESSING,
    };
  },
  [MUTATIONS.SEND_CHECKOUT_SESSION_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.SEND_CHECKOUT_SESSION_ERROR,
      error,
    };
  },
  [MUTATIONS.SEND_CHECKOUT_SESSION_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.SEND_CHECKOUT_SESSION_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.DELETE_CHECKOUT_SESSION_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.DELETE_CHECKOUT_SESSION_PROCESSING,
    };
  },
  [MUTATIONS.DELETE_CHECKOUT_SESSION_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.DELETE_CHECKOUT_SESSION_ERROR,
      error,
    };
  },
  [MUTATIONS.DELETE_CHECKOUT_SESSION_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.DELETE_CHECKOUT_SESSION_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.SEND_SECONDARY_DOCUMENT_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.SEND_SECONDARY_DOCUMENT_PROCESSING,
    };
  },
  [MUTATIONS.SEND_SECONDARY_DOCUMENT_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.SEND_SECONDARY_DOCUMENT_ERROR,
      error,
    };
  },
  [MUTATIONS.SEND_SECONDARY_DOCUMENT_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.SEND_SECONDARY_DOCUMENT_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.SHOW_INITIAL_TOOLTIP](state: State): void {
    state.misc = {
      ...state.misc,
      initialTooltip: true,
    };
  },
  [MUTATIONS.HIDE_INITIAL_TOOLTIP](state: State): void {
    state.misc = {
      ...state.misc,
      initialTooltip: false,
    };
  },
  [MUTATIONS.CREATE_INVESTOR_WALLET_ID_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.CREATE_INVESTOR_WALLET_ID_PROCESSING,
    };
  },
  [MUTATIONS.CREATE_INVESTOR_WALLET_ID_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.CREATE_INVESTOR_WALLET_ID_ERROR,
      error,
    };
  },
  [MUTATIONS.CREATE_INVESTOR_WALLET_ID_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.CREATE_INVESTOR_WALLET_ID_SUCCESS,
      payload,
    };
  },
  [MUTATIONS.CREATE_INVESTOR_WITHDRAW_PROCESSING](state: State): void {
    state.operations = {
      status: 'processing',
      name: MUTATIONS.CREATE_INVESTOR_WITHDRAW_PROCESSING,
    };
  },
  [MUTATIONS.CREATE_INVESTOR_WITHDRAW_ERROR](state: State, error: string): void {
    state.operations = {
      status: 'error',
      name: MUTATIONS.CREATE_INVESTOR_WITHDRAW_ERROR,
      error,
    };
  },
  [MUTATIONS.CREATE_INVESTOR_WITHDRAW_SUCCESS](state: State, payload: unknown): void {
    state.operations = {
      status: 'success',
      name: MUTATIONS.CREATE_INVESTOR_WITHDRAW_SUCCESS,
      payload,
    };
  },
  ...vuexfireMutations,
} as MutationTree<State>;
