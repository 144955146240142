import { Module } from 'vuex';
import { State } from '@/store/models';
import { Investment } from '@/store/models/investment';
import { Asset } from '@/store/models/asset';
import BigNumber from 'bignumber.js';

export interface InvestmentsArray<T> extends Array<T> {
  totalLength?: number;
}

export default {
  state: [],
  mutations: {},
  actions: {},
  getters: {
    getFirstInvestmentYear: (state): unknown => {
      const sortedState = [...state].sort((a, b): number => {
        if (a.createdDateTime && b.createdDateTime) {
          return new BigNumber(a.createdDateTime.seconds).minus(b.createdDateTime.seconds).toNumber();
        }
        return 0;
      });

      if (sortedState.length > 0) {
        const firstInvestment = sortedState[0];
        const dateTime = firstInvestment.createdDateTime;
        if (dateTime) {
          return dateTime.toDate().getFullYear();
        }
      }

      // Return a default value if no valid investment year is found
      return 0;
    },
    investmentHasPayments:
      (state, getters): ((id: string) => unknown) =>
      (id: string): boolean =>
        !!getters.getPaymentsByInvestmentId(id).length,
    investmentHasPaidPayments:
      (state, getters): ((id: string) => unknown) =>
      (id: string): boolean =>
        !!getters.getPaidPaymentsByInvestmentId(id).length,
    investmentHasPaidOrOpenPayments:
      (state, getters): ((id: string) => unknown) =>
      (id: string): boolean =>
        !!getters.getPaidOrOpenPaymentsByInvestmentId(id).length,
    investmentsLoadMore:
      (state, getters): ((position: number) => Investment[]) =>
      (position: number): Investment[] => {
        const tempInvestments = getters.getInvestmentsNonDeletedAsset as Investment[];
        const paidInvestments = tempInvestments.filter((investment: Investment): boolean =>
          getters.investmentHasPayments(investment.id),
        );
        const investments: InvestmentsArray<Investment> = paidInvestments.slice(
          0,
          position > tempInvestments.length ? tempInvestments.length : position,
        );

        investments.totalLength = paidInvestments.length;

        return investments;
      },
    // Since we cannot do a proper 'join' to get invesments with conditioned asset, we need to do it client side
    getInvestmentsNonDeletedAsset: (state): Investment[] =>
      state.filter(
        (investment): boolean =>
          !!investment.asset.id && !(investment.asset as Asset).deleted && (investment.asset as Asset).published,
      ),
    getInvestmentById:
      (state): ((id: string) => unknown) =>
      (id: string): Investment | undefined =>
        state.find((investment): boolean => investment.id === id),
    getInvestmentByAsset:
      (state): ((id: string) => unknown) =>
      (assetId: string): Investment | undefined =>
        state.find((investment): boolean => investment.asset.id === assetId),
    // Get the number of investments that have at least one paid payment
    getLengthPaidInvestments: (state, getters): number =>
      state.filter((investment): boolean => getters.investmentHasPaidPayments(investment.id)).length,
    // Get the total Euro invested from all investments (if status of payment is 'paid')
    getTotalInvested: (state): number =>
      state.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(),
        0,
      ),
    getSharesTotalInvested: (state): number =>
      state.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA)
            .plus(!(investmentB.asset as Asset).premium ? investmentB.boughtSharesTotal || 0 : 0)
            .toNumber(),
        0,
      ),
    getTotalRepayments: (state): number =>
      state.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.totalEuroRepayments || 0).toNumber(),
        0,
      ),
    getTotalInvestedMinusRepayments: (state, getters): number =>
      new BigNumber(getters.getTotalInvested || 0).minus(Math.abs(Number(getters.getTotalRepayments)) || 0).toNumber(),
    // Get the total Share invested from all investments (if status of payment is 'paid')
    getTotalSharesInvested: (state): number =>
      state.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(),
        0,
      ),
    // Get the total Euro invested from investments on equities (if status of payment is 'paid')
    getEquitiesTotalInvested: (state): number => {
      const equityInvestments = state.filter(
        (investment): boolean => (investment.asset as Asset).fundType === 'equity',
      );
      return equityInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(),
        0,
      );
    },
    getEquitiesTotalRepayments: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'equity');
      return bondInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.totalEuroRepayments || 0).toNumber(),
        0,
      );
    },
    getEquitiesTotalInvestedMinusRepayments: (state, getters): number =>
      new BigNumber(getters.getEquitiesTotalInvested || 0).minus(getters.getEquitiesTotalRepayments || 0).toNumber(),
    // Get the total Share invested from investments on equities (if status of payment is 'paid')
    getEquitySharesTotalInvested: (state): number => {
      const equityInvestments = state.filter(
        (investment): boolean => (investment.asset as Asset).fundType === 'equity',
      );
      return equityInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(),
        0,
      );
    },
    getEquitySharesTotalRepayments: (state, getters): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'equity');
      return bondInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA)
            .plus(
              new BigNumber(investmentB.totalEuroRepayments || 0)
                .dividedBy((getters.getAssetByInvestmentId(investmentB.id) as Asset).sharePrice)
                .toNumber(),
            )
            .toNumber(),
        0,
      );
    },
    getEquitySharesTotalInvestedMinusRepayments: (state, getters): number =>
      new BigNumber(getters.getEquitySharesTotalInvested || 0)
        .minus(getters.getEquitySharesTotalRepayments || 0)
        .toNumber(),

    // Get the total Euro invested from investments on bonds (if status of payment is 'paid')
    getBondsTotalInvested: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return bondInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(),
        0,
      );
    },
    getBondsTotalRepayments: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return bondInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.totalEuroRepayments || 0).toNumber(),
        0,
      );
    },
    getBondsTotalInvestedMinusRepayments: (state, getters): number =>
      new BigNumber(getters.getBondsTotalInvested || 0).plus(getters.getBondsTotalRepayments || 0).toNumber(),
    // Get the total Share invested from investments on bonds (if status of payment is 'paid')
    getBondSharesTotalInvested: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return bondInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(),
        0,
      );
    },
    getBondSharesTotalRepayments: (state, getters): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return bondInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA)
            .plus(
              new BigNumber(investmentB.totalEuroRepayments || 0)
                .dividedBy((getters.getAssetByInvestmentId(investmentB.id) as Asset).sharePrice)
                .toNumber(),
            )
            .toNumber(),
        0,
      );
    },
    getBondSharesTotalInvestedMinusRepayments: (state, getters): number =>
      new BigNumber(getters.getBondSharesTotalInvested || 0).plus(getters.getBondSharesTotalRepayments || 0).toNumber(),
    // Get the total Euro invested from investments on loans (if status of payment is 'paid')
    getLoansTotalInvested: (state): number => {
      const loanInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'loan');
      return loanInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(),
        0,
      );
    },
    getLoansTotalRepayments: (state): number => {
      const bondInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'loan');
      return bondInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.totalEuroRepayments || 0).toNumber(),
        0,
      );
    },
    getLoansTotalInvestedMinusRepayments: (state, getters): number =>
      new BigNumber(getters.getLoansTotalInvested || 0).plus(getters.getLoansTotalRepayments || 0).toNumber(),
    // Get the total Share invested from investments on loans (if status of payment is 'paid')
    getLoanSharesTotalInvested: (state): number => {
      const loanInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'loan');
      return loanInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(),
        0,
      );
    },
    getTotalEarnings: (state): number =>
      state
        .reduce(
          (investmentA, investmentB): BigNumber => investmentA.plus(investmentB.totalEuroEarnings || 0),
          new BigNumber(0),
        )
        .toNumber(),
    getTotalLoanEarnings: (state): number => {
      const loanInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'loan');
      return loanInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.totalEuroEarnings || 0).toNumber(),
        0,
      );
    },
    getTotalEquityEarnings: (state): number => {
      const equityInvestments = state.filter(
        (investment): boolean => (investment.asset as Asset).fundType === 'equity',
      );
      return equityInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.totalEuroEarnings || 0).toNumber(),
        0,
      );
    },
    getTotalDebtEarnings: (state): number => {
      const debtInvestments = state.filter((investment): boolean => (investment.asset as Asset).fundType === 'debt');
      return debtInvestments.reduce(
        (investmentA, investmentB): number =>
          new BigNumber(investmentA).plus(investmentB.totalEuroEarnings || 0).toNumber(),
        0,
      );
    },
    // Total paid of investments of an specific asset type
    getTypeTotalInvested:
      (state): ((id: string) => unknown) =>
      (type: string): number =>
        state
          .filter((investment): boolean => (investment.asset as Asset).fundType === type)
          .reduce(
            (investmentA, investmentB): number =>
              new BigNumber(investmentA)
                .plus(investmentB.paidEuroTotal || 0)
                .minus(investmentB.totalEuroRepayments || 0)
                .toNumber(),
            0,
          ),
    // Total initial paid of investments of an specific asset type
    getTypeTotalInitialInvested:
      (state): ((id: string) => unknown) =>
      (type: string): number =>
        state
          .filter((investment): boolean => (investment.asset as Asset).fundType === type)
          .reduce(
            (investmentA, investmentB): number =>
              new BigNumber(investmentA).plus(investmentB.paidEuroTotal || 0).toNumber(),
            0,
          ),
    // Total initial shares of investments of an specific asset type
    getTypeTotalCurrentInvested:
      (state): ((id: string) => number) =>
      (type: string): number =>
        state
          .filter((investment): boolean => (investment.asset as Asset).fundType === type)
          .reduce(
            (investmentA, investmentB): number =>
              new BigNumber(investmentA)
                .plus(
                  new BigNumber(investmentB.boughtSharesTotal || 0)
                    .minus(investmentB.totalSharesRepayments || 0)
                    .multipliedBy((investmentB.asset as Asset).sharePrice),
                )
                .toNumber(),
            0,
          ),
    // Total initial shares of investments of an specific asset type
    getTypeTotalInitialShares:
      (state): ((id: string) => unknown) =>
      (type: string): number =>
        state
          .filter((investment): boolean => (investment.asset as Asset).fundType === type)
          .reduce(
            (investmentA, investmentB): number =>
              new BigNumber(investmentA).plus(investmentB.boughtSharesTotal || 0).toNumber(),
            0,
          ),
    // Total shares of investments of an specific asset type
    getTypeTotalCurrentShares:
      (state): ((id: string) => unknown) =>
      (type: string): number =>
        state
          .filter((investment): boolean => (investment.asset as Asset).fundType === type)
          .reduce(
            (investmentA, investmentB): number =>
              new BigNumber(investmentA)
                .plus(investmentB.boughtSharesTotal || 0)
                .minus(investmentB.totalSharesRepayments || 0)
                .toNumber(),
            0,
          ),
    // Total earnings of investments of an specific asset type
    getTypeTotalEarnings:
      (state): ((id: string) => unknown) =>
      (type: string): number =>
        state
          .filter((investment): boolean => (investment.asset as Asset).fundType === type)
          .reduce(
            (investmentA, investmentB): number =>
              new BigNumber(investmentA).plus(investmentB.totalEuroEarnings || 0).toNumber(),
            0,
          ),
    // Return of investments of an specific asset type in Euro (current value - initial value)
    getTypeEuroReturn:
      (state, getters): ((id: string) => unknown) =>
      (type: string): number => {
        const current = getters.getTypeTotalCurrentInvested(type);
        const initial = getters.getTypeTotalInvested(type);
        return new BigNumber(current).minus(initial).toNumber();
      },
    // Return of investments of an specific asset type in Percentage (current value / initial value)
    getTypePercentageReturn:
      (state, getters): ((id: string) => unknown) =>
      (type: string): number => {
        const current = getters.getTypeTotalCurrentInvested(type);
        const initial = getters.getTypeTotalInvested(type);
        return new BigNumber(current).dividedBy(initial).minus(1).multipliedBy(100).toNumber();
      },
    // Total return of investments of an specific asset type in Euro (current value + earnings - initial value)
    getTypeTotalEuroReturn:
      (state, getters): ((id: string) => unknown) =>
      (type: string): number => {
        const current = getters.getTypeTotalCurrentInvested(type);
        const initial = getters.getTypeTotalInvested(type);
        const earnings = getters.getTypeTotalEarnings(type);
        return new BigNumber(current).plus(earnings).minus(initial).toNumber();
      },
    // Total return of investments of an specific asset type in Percentage ((current value + earnings) / initial value)
    getTypeTotalPercentageReturn:
      (state, getters): ((id: string) => unknown) =>
      (type: string): number => {
        const current = getters.getTypeTotalCurrentInvested(type);
        const initial = getters.getTypeTotalInvested(type);
        const earnings = getters.getTypeTotalEarnings(type);
        return new BigNumber(current).plus(earnings).dividedBy(initial).minus(1).multipliedBy(100).toNumber();
      },
    // Return of investment in Euro (current value - initial value)
    getInvestmentEuroReturn:
      (state, getters): ((id: string) => unknown) =>
      (investmentId: string): number => {
        const investment: Investment = getters.getInvestmentById(investmentId);
        const initial = new BigNumber(investment.paidEuroTotal || 0).minus(investment.totalEuroRepayments || 0);
        const current = new BigNumber(investment.boughtSharesTotal || 0)
          .minus(investment.totalSharesRepayments || 0)
          .multipliedBy((investment.asset as Asset).sharePrice || 0);
        return current.minus(initial).toNumber();
      },
    // Return of investment in percentage (current value / initial value)
    getInvestmentPercentageReturn:
      (state, getters): ((id: string) => unknown) =>
      (investmentId: string): number => {
        const investment: Investment = getters.getInvestmentById(investmentId);
        const initial = new BigNumber(investment.paidEuroTotal || 0).minus(investment.totalEuroRepayments || 0);
        const current = new BigNumber(investment.boughtSharesTotal || 0)
          .minus(investment.totalSharesRepayments || 0)
          .multipliedBy((investment.asset as Asset).sharePrice || 0);
        return current.minus(initial).dividedBy(initial).multipliedBy(100).decimalPlaces(10).toNumber();
      },
    // Total return of investment in Euro (current value + earnings - initial value)
    getInvestmentTotalEuroReturn:
      (state, getters): ((id: string) => unknown) =>
      (investmentId: string): number => {
        const investment: Investment = getters.getInvestmentById(investmentId);
        const initial = new BigNumber(investment.paidEuroTotal || 0).minus(investment.totalEuroRepayments || 0);
        const current = new BigNumber(investment.boughtSharesTotal || 0)
          .minus(investment.totalSharesRepayments || 0)
          .multipliedBy((investment.asset as Asset).sharePrice || 0);
        const earnings = investment.totalEuroEarnings || 0;
        return current.plus(earnings).minus(initial).decimalPlaces(2).toNumber();
      },
    // Total return of investment in percentage ((current value + earnings) / initial value)
    getInvestmentTotalPercentageReturn:
      (state, getters): ((id: string) => unknown) =>
      (investmentId: string): number => {
        const investment: Investment = getters.getInvestmentById(investmentId);
        const initial = new BigNumber(investment.paidEuroTotal || 0).minus(investment.totalEuroRepayments || 0);
        const current = new BigNumber(investment.boughtSharesTotal || 0)
          .minus(investment.totalSharesRepayments || 0)
          .multipliedBy((investment.asset as Asset).sharePrice || 0);
        const earnings = investment.totalEuroEarnings || 0;
        return current.plus(earnings).dividedBy(initial).minus(1).multipliedBy(100).toNumber();
      },
  },
} as Module<Investment[], State>;
