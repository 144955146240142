import { Module } from 'vuex';
import { State } from '@/store/models';
import { Checkout } from '@/store/models/checkout';
import { clientConfig } from '@/helpers/clientData';

export const UPDATE_CHECKOUT = 'UPDATE_CHECKOUT';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';

const whitelabelConfig = clientConfig();
const initCheckoutStoreClient = whitelabelConfig.functionality.checkout.initCheckoutStore;

export const initCheckoutStore = (client: string, initValues?: Partial<Checkout>): Checkout => {
  if (client === 'zib') {
    return {
      assetHideDividendsFormat: undefined,
      // If remotecheckout is enabled, active needs to start as false.
      active: initValues?.active || null,
      assetId: initValues?.assetId || '',
      euroAmount: initValues?.euroAmount || 0,
      selectedDividendsFormatYear: initValues?.selectedDividendsFormatYear || ['0', 0],
      sharesAmount: initValues?.sharesAmount || 0,
      totalEmissionCosts: initValues?.totalEmissionCosts || 0,
      totalEur: initValues?.totalEur || 0,
      nextStep: initValues?.nextStep || undefined,
      isPepAnswer: initValues?.isPepAnswer || '',
      isUboAnswer: initValues?.isUboAnswer || '',
      questionaireQuestionAndAnswers: initValues?.questionaireQuestionAndAnswers || [],
      knowledgeQuestionAndAnswers: initValues?.knowledgeQuestionAndAnswers || [],
      knowledgeQuestionAndAnswersUnprocessed: initValues?.knowledgeQuestionAndAnswersUnprocessed || [],
      experienceQuestionAndAnswers: initValues?.experienceQuestionAndAnswers || [],
      experienceQuestionAndAnswersUnprocessed: initValues?.experienceQuestionAndAnswersUnprocessed || [],
      questionaireVersion: initValues?.questionaireVersion || '',
      signatureChoice: initValues?.signatureChoice || '',
      freelyInvestedAssets: initValues?.freelyInvestedAssets || 0,
      simulationCalcuationResult: initValues?.simulationCalcuationResult || 0,
      netAnnualIncome: 0,
      liquidAssetsValue: 0,
      financialCommitmentsValue: 0,
      incomeType: null,
      agressWithRisk: null,
      agressWithRiskWarning: null,
    };
  }
  // Default
  return {
    assetHideDividendsFormat: initValues?.assetHideDividendsFormat || undefined,
    assetId: initValues?.assetId || '',
    euroAmount: initValues?.euroAmount || 0,
    selectedDividendsFormatYear: initValues?.selectedDividendsFormatYear || ['0', 0],
    sharesAmount: initValues?.sharesAmount || 0,
    totalEmissionCosts: initValues?.totalEmissionCosts || 0,
    totalEur: initValues?.totalEur || 0,
    nextStep: initValues?.nextStep || undefined,
  };
};

export default {
  state: initCheckoutStore(initCheckoutStoreClient),
  mutations: {
    [UPDATE_CHECKOUT](state: Checkout, checkoutObject: Checkout): void {
      Object.assign(state, checkoutObject);
    },
    [RESET_CHECKOUT](state: Checkout, initValue: Partial<Checkout>): void {
      Object.assign(state, initCheckoutStore(initCheckoutStoreClient, initValue));
    },
  },
  actions: {
    updateCheckoutAction({ commit }, data: Checkout): void {
      commit(UPDATE_CHECKOUT, data);
    },
    resetCheckoutAction({ commit }, initValue: Partial<Checkout>): void {
      commit(RESET_CHECKOUT, initValue);
    },
  },
  getters: {
    getCheckout(state): Checkout {
      return state;
    },
    getAssetAddress(state, getters): string {
      const asset = getters.getAssetById(state?.assetId);
      if (!asset) {
        return '';
      }

      return asset.street && asset.houseNumber && asset.postalCode && asset.city
        ? `${asset.street} ${asset.houseNumber}, ${asset.postalCode} ${asset.city}`
        : asset.city;
    },
  },
} as Module<Checkout, State>;
